body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/** Flex */
.grow {
  flex-grow: 1 !important;
}

/** Text  */
.strikeThrough {
  text-decoration: line-through !important;
}

.fw100 {
  font-weight: 100 !important;
}

.fw200 {
  font-weight: 200 !important;
}

.fw300 {
  font-weight: 300 !important;
}

.fw400 {
  font-weight: 400 !important;
}

.fw500 {
  font-weight: 500 !important;
}

.fw600 {
  font-weight: 600 !important;
}

.fw700 {
  font-weight: 700 !important;
}

.fw800 {
  font-weight: 800 !important;
}

.fw900 {
  font-weight: 900 !important;
}

/* Ensure the background styles the same */
input:-webkit-autofill {
  background-color: transparent !important;
  box-shadow: 0 0 0px 1000px #1a80d3 inset !important;
  color: inherit !important;
}

/* Fix text color if autofill applies a default color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  -webkit-text-fill-color: #fff !important;
  transition: background-color 5000s ease-in-out 0s;
}
